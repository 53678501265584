import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    Coupons,
    DailyReportResponse,
    Event,
    GroupInvocesCreateRequest,
    GroupInvocesRemoveRequest,
    GroupInvocesRequest,
    GroupInvoice,
    GroupInvoicePayer,
    InvoiceMain,
    InvoicePartner,
    InvoiceReport,
    PivotDef,
    SendInvoiceNotificationInfo,
    ServiceVisit,
} from '../../../../_shared/model';
import { InvoiceServiceCalculateResponse } from '../../../../_shared/model/accounting/invoiceReport/invoiceServiceCalculateResponse.model';
import { Invoice } from '../../../../_shared/model/invoice.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { CouponPayment } from './../../../../_shared/model/accounting/coupon/couponPayment.model';
import { ChangePayed } from './../../../../_shared/model/accounting/invoice/changePayed.model';
import { InvoiceRecipient } from './../../../../_shared/model/accounting/invoiceRecipient.model';
import { InvoiceServiceCalculatePayload } from './../../../../_shared/model/accounting/invoiceReport/invoiceServiceCalculatePayload.model';
import { OnlinePayment } from './../../../../_shared/model/accounting/onlinePayment/online-payment.model';
import { ResendXml } from './../../../../_shared/model/accounting/partners/resend-xml';

@Injectable({
    providedIn: 'root',
})
export class AccountingService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPivotTableData(contractorid: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-data?from=${from}&to=${to}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removePivotDefs(contractorid: number, pivotDefId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs/${pivotDefId}`);
    }

    public getPivotDefs(contractorid: number): Observable<PivotDef[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addPivotDefs(contractorid: number, data: PivotDef): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs`, data, {
            responseType: 'text',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getGroupInvoiceReportPreview(contractorId: number, data: InvoiceReport): Observable<any> {
        return this.httpClient.getAttachmentPost(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation/preview`,
            data,
        );
    }

    // public getGroupInvoiceReportPreview(contractorId: number, data: InvoiceReport): Observable<any> {
    //     return this.httpClient.getAttachmentPost(
    //         this.helper.getAccountingUrl() + `/contractors/${contractorId}/groupInvoice/preview`,
    //         data,
    //     );
    // }

    public getInvoicePreviewGeneric(
        contractorId: number,
        data: InvoiceReport | Invoice,
        isCopy: boolean = false,
        headerAndFooter: boolean = false,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> {
        let url = `/contractors/${contractorId}/invoices/preview/generic`;
        let char = '?';
        if (isCopy) {
            url += '?copy=true';
            char = '&';
        }
        if (headerAndFooter) {
            url += `${char}rerender=true`;
        }
        // return this.httpClient.getAttachment(this.helper.getAccountingUrl() + url);
        return this.httpClient.getAttachmentPost(this.helper.getAccountingUrl(2) + url, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCouponPreview(contractorId: number, couponPaymentId: number): Observable<any> {
        const url = `/contractors/${contractorId}/couponPayments/${couponPaymentId}/preview`;
        // if (isCopy) {
        //     url += '?copy=true';
        // }
        // return this.httpClient.getAttachment(this.helper.getAccountingUrl() + url);
        return this.httpClient.getAttachment(this.helper.getAccountingUrl(2) + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getInvoicePreview(contractorId: number, visitId: number, isCopy: boolean = false): Observable<any> {
        let url = `/contractors/${contractorId}/visits/${visitId}/preview`;
        if (isCopy) {
            url += '?copy=true';
        }
        return this.httpClient.getAttachment(this.helper.getAccountingUrl() + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addServiceOnVisit(contractorId: number, visitId: number, visitService: ServiceVisit): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitId + '/services', visitService, {
            responseType: 'text',
        });
    }

    public getAllServicesOnVisit(visitId: string): Observable<ServiceVisit[]> {
        return this.httpClient
            .get(this.helper.getVisitUrl(2) + '/visits/' + visitId + '/services')
            .pipe(map(res => res.map(el => new ServiceVisit().deserialize(el))));
    }

    // .map(res => {
    //     return res.json().map(el => {
    //         return new ServiceVisit().deserialize(el);
    //     });
    // });

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateServiceVisit(contractorId: number, visitId: number, visitService: ServiceVisit): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/services/' + visitService.service.id, visitService);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteServiceVisit(visitId: string, internalId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/services/' + internalId);
    }

    //createInvoiceFromVisit()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createInvoiceFromVisit(contractorId: number, visitId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId);
    }

    //createInvoiceGeneric()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createInvoiceGeneric(contractorId: number, data: InvoiceReport): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/generic`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createCouponPayment(contractorId: number, data: InvoiceReport): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/couponPayments`, data);
    }

    // getInvoiceForInvoiceId
    public getInvoiceForInvoiceId(contractorId: number, invoiceid: number, print: boolean = false): Observable<InvoiceReport> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoices/${invoiceid}/data?print=${print}`);
    }

    public getGroupInvoiceForGroupId(contractorId: number, groupInvoiceid: number): Observable<InvoiceReport> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation/${groupInvoiceid}`);
    }
    // //createInvoice()
    // public createInvoice(contractorId: number, visitId: number): Observable<any> {
    //     return this.httpClient.getAttachment(
    //         this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId
    //     );
    // }

    //statusInvoice()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getInvoiceStatus(contractorId: number, visitId: string): Observable<any> {
        return this.httpClient.get(this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId + '/status');
    }

    //sendDiagnose()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendInvoice(contractorid: number, invoiceid: number, data: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/invoices/${invoiceid}/mail`, data);
    }
    //getInvoices()
    public getInvoices(contractorId: number, from: string, to: string): Observable<Invoice[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + '/contractors/' + contractorId + '?from=' + from + '&to=' + to).pipe(
            map(res => {
                if (!_.isNil(res.invoiceStat)) {
                    return _.get(res, 'invoiceStat', []).map(el => {
                        return new Invoice().deserialize(el);
                    });
                }
                return [];
            }),
        );
    }

    public getCouponReceipsList(contractorId: number, from: string, to: string): Observable<CouponPayment[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/couponPayments?from=${from}&to=${to}`);
        // .pipe(
        //     map((res) => {
        //         if (!_.isNil(res.invoiceStat)) {
        //             return _.get(res, 'invoiceStat', []).map((el) => {
        //                 return new Invoice().deserialize(el);
        //             });
        //         }
        //         return [];
        //     }),
        // );
    }

    public getCouponReceip(contractorId: number, id: number): Observable<CouponPayment> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/couponPayments/${id}`);
    }

    //createInvoice()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getInvoice(contractorId: number, invoiceId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/invoices/' + invoiceId);
    }
    public getReportStats(dateFrom, dateTo, contractorId, type) {
        return this.httpClient.getAttachmentPost(`${this.helper.getStatsUrl()}/contractors/${contractorId}/${type}`, {
            dateFrom: dateFrom,
            dateTo: dateTo,
        });
    }

    //deleteInvoice()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public cancelInvoice(contractorId: number, invoiceId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAccountingUrl(2) + '/contractors/' + contractorId + '/invoices/' + invoiceId);
    }

    //setPayerName()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setPayerName(contractorId: number, visitId: string, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/${visitId}/company`, data);
    }

    //createPOSInvoice()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createPOSInvoice(contractorId: number, visitId: number): Observable<any> {
        return this.httpClient.get(this.helper.getAccountingUrl() + `/contractors/${contractorId}/visits/${visitId}/pos`);
    }

    // REPORTS

    //prepareReports()
    public prepareReports(
        contractorId: number,
        reportName: string,
        reportType: string,
        from: string,
        to: string,
        customParam: string = '',
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/reports?reportName=${reportName}&reportType=${reportType}&from=${from}&to=${to}${customParam}`,
        );
    }

    //contractorReports()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public invoicesZip(contractorId: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoices/zip?from=${from}&to=${to}`);
    }
    // createInvoiceReportFromVisit()
    public createInvoiceReportFromVisit(contractorId: number, visitId: number): Observable<InvoiceReport> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitId}/data`);
    }

    //prepareInvoiceData
    public prepareInvoiceDataVisit(contractorId: number, visitId: number): Observable<InvoiceMain> {
        return this.httpClient.get(this.helper.getAccountingUrl() + `/contractors/${contractorId}/visits/${visitId}/invoice`).pipe(
            map(el => {
                return new InvoiceMain().deserialize(el);
            }),
        );
    }

    //getInvoicesForVisit()
    public getInvoicesForVisit(contractorId: number, visitId: number): Observable<InvoiceReport[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitId}/invoices`);
    }

    //getInvoicesForCustomer()
    public getInvoicesForCustomer(contractorId: number, customerId: number): Observable<InvoiceReport[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/customers/${customerId}/invoices`);
    }

    //getInvoicesTemplateEmploye()
    public getInvoicesTemplateEmploye(contractorId: number, userId: number): Observable<InvoiceReport> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/users/${userId}/invoices/template`);
    }

    //getInvoicesTemplate()
    public getInvoicesTemplate(contractorId: number, subcontractorId: number, userId: number): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/subcontractors/${subcontractorId}/users/${userId}/invoices/template`,
        );
    }

    //getInvoicesTemplate()
    public getInvoicesTemplateCustomer(contractorId: number, customerId: number, userId: number): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/users/${userId}/customers/${customerId}/invoices/template`,
        );
    }

    //prepareDailyReport
    public prepareDailyReport(contractorId: number, reportDate: string): Observable<DailyReportResponse> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/dailyreport?reportDate=${reportDate}`);
    }

    //getUnusedCoupons
    public getUnusedCoupons(contractorId: number): Observable<Coupons[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/coupons`);
    }

    //changePaymentType
    public changePaymentType(contractorId: number, invoiceRequest: InvoiceReport): Observable<DailyReportResponse> {
        return this.httpClient.put(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/paymentType`, invoiceRequest);
    }

    //getInvoicePartners()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getInvoicePartners(contractorId: number): Observable<any[]> {
        return this.httpClient.get(this.helper.getAccountingUrl() + `/contractors/${contractorId}/invoicePartners`);
    }

    //createUpdateInvoicePartner()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createUpdateInvoicePartner(contractorId: number, data: InvoicePartner): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartner`, data);
    }

    public searchInvoicePartners(contractorId: number, query: string): Observable<InvoicePartner[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartners/search?query=${query}`);
    }
    public getInvoicePartner(contractorId: number, partnerId: number) {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartner/${partnerId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendPartnerXml(contractorId: number, bsId: number, year: number, month: number, resend?: ResendXml): Observable<any> {
        return this.httpClient.post(
            this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsId}/years/${year}/months/${month}/xml`,
            resend ?? {},
        );
    }
    // @Post("/contractors/{contractorId}/bs/{bsId}/years/{year}/months/{month}/xml")

    public getContractorInvoiceRecipientsBySearch(contractorId: number, query: string): Observable<InvoiceRecipient[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoiceRecipients/search?query=${query}`);
    }

    //createGroupInvoiceConfirmation()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addToGroupInvoice(contractorId: number, invoiceRequest: InvoiceReport): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation`, invoiceRequest);
    }

    //getContractorGroupInvoicePayers()
    public getContractorGroupInvoicePayers(
        contractorId: number,
        showOpenOnly: boolean = false,
        searchText?: string,
    ): Observable<GroupInvoicePayer[]> {
        let apiCall = this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoicePayers/search?showOpenOnly=${showOpenOnly}`;
        if (searchText) {
            apiCall += `?searchText=${searchText}`;
        }
        return this.httpClient.get(apiCall);
    }

    //getContractorPayerGroupInvoices ()
    public getContractorPayerGroupInvoices(
        contractorId: number,
        data: GroupInvocesRequest,
        from: string,
        to: string,
        showOpenOnly: boolean = true,
    ): Observable<GroupInvoice[]> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/payerGroupInvoices/search?from=${from}&to=${to}&showOpenOnly=${showOpenOnly}`,
            data,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public previewInvoiceFromGroupInvoiceRequest(contractorId: number, data: GroupInvocesCreateRequest): Observable<any> {
        return this.httpClient.getAttachmentPost(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoice/preview`, data);
    }

    //createInvoiceFromGroupInvoiceRequest ()
    public createInvoiceFromGroupInvoiceRequest(contractorId: number, data: GroupInvocesCreateRequest): Observable<Invoice> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoice`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getGroupInvoicesForVisit(contractorId: number, visitid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitid}/groupInvoiceConfirmations`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getGroupInvoicesForCustomer(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/customers/${customerId}/groupInvoiceConfirmations`,
        );
    }

    public getReservationWithNoInvoice(contractorId: number, from: string = undefined, to: string = undefined): Observable<Event[]> {
        let url = `/contractors/${contractorId}/reservations/noinvoice`;
        //YYYY-MM-DD
        if (from && to) {
            url += `?from=${from}&to=${to}`;
        }
        return this.httpClient.get(this.helper.getAccountingUrl(2) + url);
    }

    //deleteGroupInvoiceDetails ()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteGroupInvoiceDetails(contractorId: number, data: GroupInvocesRemoveRequest): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/deleteGroupInvoice`, data);
    }

    public getOnlinePayments(contractorId: number, from: string = undefined, to: string = undefined): Observable<OnlinePayment[]> {
        let url = `/contractors/${contractorId}/onlinePayments`;
        //YYYY-MM-DD
        if (from && to) {
            url += `?from=${from}&to=${to}`;
        }
        return this.httpClient.get(this.helper.getAccountingUrl(2) + url);
    }

    public getSingleOnlinePayment(contractorId: number, id: number): Observable<OnlinePayment> {
        const url = `/contractors/${contractorId}/onlinePayments/${id}`;

        return this.httpClient.get(this.helper.getAccountingUrl(2) + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getOnlinePaymentsPreview(contractorId: number, opid: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/onlinePayments/${opid}/preview`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public refundPayment(contractorId: number, opid: number, refundRequest: any): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl() + `/contractors/${contractorId}/refunds/${opid}`, refundRequest);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendRefundDocument(contractorId: number, customerid: number, refundid: number, email: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/customer/${customerid}/onlinepayments/${refundid}/mail`,
            email,
        );
    }

    public changePayed(
        contractorId: number,
        invoiceId: number,
        data: ChangePayed = {
            paymentDate: null,
        },
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> {
        return this.httpClient.put(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoices/${invoiceId}/pay`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createUpdateInvoiceRecipient(contractorId: number, data: InvoiceRecipient): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoiceRecipient`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public connectPartnerAndRecipient(contractorId: number, partnerid: number, recipientid: number): Observable<any> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/connect/partner/${partnerid}/recipient/${recipientid}`,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removeInvoicePayer(contractorId: number, partnerId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartner/${partnerId}`);
    }
    public getSentInvoicesForPayer(contractorId: number, partnerId: number) {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartner/${partnerId}/invoices/sent`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public voidCouponPayment(contractorId: number, couponId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/couponPayments/${couponId}`);
    }

    public shareCouponWithCustomer(contractorId: number, customerId: number, couponPaymentId: number, data: SendInvoiceNotificationInfo) {
        return this.httpClient.post(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerId}/couponpayments/${couponPaymentId}/share`,
            data,
        );
    }
    public stopShareWithCustomer(contractorId: number, customerId: number, couponPaymentId: number) {
        return this.httpClient.put(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerId}/couponpayments/${couponPaymentId}/unshare`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendCoupon(contractorid: number, couponId: number, data: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/couponpayments/${couponId}/mail`, data);
    }

    public getCouponPayments(contractorId: number, customerId?: number, visitId?: number): Observable<CouponPayment[]> {
        let url = `/contractors/${contractorId}/couponPayments/`;
        if (customerId) {
            url += `?customerId=${customerId}`;
        } else {
            url += `?visitId=${visitId}`;
        }
        return this.httpClient.get(this.helper.getAccountingUrl(2) + url);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createInvoiceForPromotion(contractorId: number, visitId: number): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitId}/promotioninvoices`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public ignoreXMLCase(contractorId: number, bsId: number, caseId: string, invoiceNo?: string): Observable<any> {
        let param = '';
        if (invoiceNo) {
            param = `?invoiceNo=${invoiceNo}`;
        }
        return this.httpClient.post(this.helper.getErpRootUrl('bsapi') + `/contractors/${contractorId}/bs/${bsId}/case/${caseId}${param}`);
    }
    public calculateItemPrice(payload: InvoiceServiceCalculatePayload): Observable<InvoiceServiceCalculateResponse> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/invoices/item/calculate`, payload);
    }

    // @Post("/contractors/{contractorId}/bs/{bsId}/case/{caseId}")
}
