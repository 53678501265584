import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppBasicsModule } from '@meddev/fe-shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
import { MfAutoFocusDirective } from '../../_directives/mfAutoFocus.directive';
// directives
import { MfMinutesFromMidnightDirective } from '../../_directives/mfMinutesFromMidnight.directive';
import { NoDblClickDirective } from '../../_directives/no-dbl-click.directive';
import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
// modules
import { ComponentModule } from '../components/component.module';
import { HomeComponent } from '../pages/home/home.component';
import { SettingsComponent } from '../pages/settings/settings.component';
import { OverlayContentDirective } from './../../_directives/overlayContent.directive';
import { AsideNavComponent } from './aside-nav/aside-nav.component';
import { AsnHdStatusComponent } from './aside-nav/asn-hd-status/asn-hd-status.component';
import { AsnStatusComponent } from './aside-nav/asn-status/asn-status.component';
import { AsnTelephonyStatusComponent } from './aside-nav/asn-telephony-status/asn-telephony-status.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderNavComponent } from './header-nav/header-nav.component';
// components
import { HeaderNotificationElementComponent } from './header-nav/header-notification-element/header-notification-element.component';
import { HeaderNotificationsComponent } from './header-nav/header-notifications/header-notifications.component';
import { QuickSidebarComponent } from './quick-sidebar/quick-sidebar.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { TooltipsComponent } from './tooltips/tooltips.component';

@NgModule({
    declarations: [
        HeaderNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        HomeComponent,
        MfAutoFocusDirective,
        NoDblClickDirective,
        SettingsComponent,
        AsideNavComponent,
        MfMinutesFromMidnightDirective,
        HeaderNotificationElementComponent,
        HeaderNotificationsComponent,
        AsnHdStatusComponent,
        AsnStatusComponent,
        AsnTelephonyStatusComponent,
        OverlayContentDirective,
    ],
    exports: [
        HeaderNavComponent,
        FooterComponent,
        QuickSidebarComponent,
        ScrollTopComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        MfAutoFocusDirective,
        NoDblClickDirective,
        MfMinutesFromMidnightDirective,
        AsideNavComponent,
        OverlayContentDirective,
    ],
    imports: [CommonModule, RouterModule, ComponentModule, NgxPermissionsModule, NgbModule, NgScrollbarModule, OverlayModule, AppBasicsModule],
    // entryComponents: [EditTextPopupComponent]
})
export class LayoutModule {}
