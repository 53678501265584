import * as _ from 'lodash';
import * as moment from 'moment';
import { Event } from '../event.model';

export class EventNew extends Event {
    from: string;
    to: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recurring: any;
    newCustomer: boolean;
    originDate: moment.Moment;
    // reservationType: ReservationType;
    // recurringUntil: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        super.deserialize(input);
        this.from = input.from;
        this.to = input.to;
        this.recurring = input.recurring;
        this.newCustomer = input.newCustomer;
        this.originDate = input.originDate;
        // this.reservationType = input.reservationType;
        // this.recurringUntil = input.recurringUntil;
        return this;
    }
}
