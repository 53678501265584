import * as _ from 'lodash';
import * as moment from 'moment';
import { tags } from '../constants/customTags.const';
import { BsType as bsType } from '../enums/business.enum';
import { ReservationMode } from '../enums/reservationMode.enum';
import { VisitExitType } from '../enums/visitExitType.enum';
import { ReservationType } from './../enums/schedule/reservationType.enum';
import { Asset } from './asset.model';
import { Contractor } from './contractor.model';
import { Customer } from './customer.model';
import { Serializable } from './deserialize.service';
import { ReservationPromotion } from './reservation/reservation-promotion.model';
import { Case } from './schedule/case.model';
import { Service } from './service.model';
import { ServiceGroup } from './serviceGroup.model';

export class Event implements Serializable<Event> {
    additionalPrereservationId: string;
    aggregateId: number;
    asset: Asset;
    backgroundClass: string;
    backgroundColor: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bsid: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bsName: any;
    bsType?: bsType;
    case: Case;
    caseId: string;
    caseInternalId: string;
    className: string;
    comment: string;
    contractor: Contractor;
    customer: Customer;
    customTagId: number;
    dotClass: string;
    editable: boolean;
    end: moment.Moment;
    forceUpdate: boolean;
    fromNowOn: string;
    isBs: boolean; // is business subject
    isConcluded: boolean;
    isDraft: boolean;
    isRecurring: boolean;
    noShow: boolean;
    prereservationId: string;
    recurringId: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    recurringType: any;
    recurringUntil: moment.Moment;
    reservationMode: ReservationMode;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resourceId: any;
    service: Service;
    start: moment.Moment;
    status: number;
    textColor: string;
    title: string;
    treatmentId: number;
    visitEndDate: moment.Moment;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    visitId: any;
    telemed: boolean;
    phoneCall: boolean;
    serviceGroup: ServiceGroup;
    originUrl: string;
    isPaid: boolean;
    paymentId: number;
    refundId: number;
    reservationType: ReservationType;
    callStart: moment.Moment;
    duration: number;
    ignoreUnprocessedInvoice: boolean;
    visitManualBsId: number;
    visitManualCaseId: string;
    concludeStatus: VisitExitType;
    concludeComment: string;
    reservationPromotion: ReservationPromotion;
    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.caseInternalId = input.caseInternalId;

        this.case = new Case().deserialize(input.case);
        this.isDraft = input.isDraft;
        this.additionalPrereservationId = input.additionalPrereservationId;
        this.forceUpdate = input.forceUpdate;
        this.fromNowOn = input.fromNowOn;
        this.contractor = new Contractor().deserialize(input.contractor);
        this.service = new Service().deserialize(input.service);
        this.recurringId = input.recurringId;
        this.isRecurring = input.isRecurring;
        this.refundId = input.refundId;
        if (!_.isNil(input.recurringId)) {
            this.isRecurring = true;
        }
        this.isBs = input.isBs;
        this.originUrl = input.originUrl;
        this.isPaid = input.isPaid;
        this.treatmentId = input.treatmentId;
        this.isConcluded = input.isConcluded;
        this.customer = new Customer().deserialize(input.customer);
        this.caseId = input.caseId;
        this.customTagId = input.customTagId;
        this.serviceGroup = input.serviceGroup;
        if (input.reservationFrom) {
            this.start = moment(input.reservationFrom);
        } else {
            this.start = moment(input.start);
        }

        if (input.reservationTo) {
            this.end = moment(input.reservationTo);
        } else {
            this.end = moment(input.end);
        }

        if (input.recurringUntil) {
            this.recurringUntil = moment(input.recurringUntil);
        }

        this.comment = input.comment;
        this.recurringType = input.recurringType;
        this.title = input.title;

        // this.data = input;
        this.visitId = input.visitId;
        this.prereservationId = input.prereservationId;
        this.aggregateId = input.aggregateId;
        // if (!input.resourceId) {
        //     this.resourceId = this.data.contractor.subcontractors[0].id;
        // } else {
        //     this.resourceId = input.resourceId;
        // }

        if (input.visitEndDate) {
            this.visitEndDate = moment(input.visitEndDate);
        } //reservationType
        //ce je definiran uporstevamo tega!
        this.reservationMode = input.reservationMode;
        this.noShow = input.noShow;
        this.dotClass = 'm-fc-event--pink';
        if (!_.isNil(input.status)) {
            if (input.status === 2) {
                this.reservationMode = ReservationMode.RESERVE;
            } else if (input.status === 7) {
                this.reservationMode = ReservationMode.DRAFT;
            }
        }
        this.bsid = input.bsid;
        this.bsName = input.bsName;
        this.bsType = input.bsType;
        this.paymentId = input.paymentId;
        this.reservationType = input.reservationType;
        this.callStart = input.callStart;
        this.duration = input.duration;

        if (this.customTagId) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tag: any = tags.CUSTOM_TERMS_TAGS.find(el => el.id === this.customTagId);
            if (tag) {
                this.className = this.className + ' ' + tag.classColor;
            }
        }

        this.editable = input.editable;

        if (input.asset) {
            this.asset = new Asset().deserialize(input.asset);
        }

        this.telemed = input.telemed;
        this.phoneCall = input.phoneCall;
        this.ignoreUnprocessedInvoice = input.ignoreUnprocessedInvoice;
        this.visitManualBsId = input.visitManualBsId;
        this.visitManualCaseId = input.visitManualCaseId;
        this.reservationPromotion = input.reservationPromotion;
        this.concludeStatus = input.concludeStatus;
        this.concludeComment = input.concludeComment;

        return this;
    }
}

/*
m-fc-event--solid-primary
m-fc-event--solid-focus
m-fc-event--solid-danger
m-fc-event--solid-warning
m-fc-event--solid-success
*/

// id: 1000,
// title: 'Repeating Event',
// description: 'Lorem ipsum dolor sit amet, labore',
// start: YM + '-16T16:00:00'
